import React, { useRef, useState } from "react";
import confetti from "canvas-confetti"
import pop from "../Assets/pop.mp3"

const ResultChild = (props) => {

    const audioRef = useRef(null)

    const handleConfetti = () => {
        confetti({
          particleCount: 400, // Number of confetti particles
          spread: 90,         // Spread angle
          origin: { y: 0.6 },  // Starting point of confetti (relative to the element)
          zIndex: 9999        // Make sure confetti is on top of everything
        });

        if (audioRef.current) {
            audioRef.current.play();
          }

        props.onCardClick(props.id); // Call the parent's click handler to manage the selection
      };
    
    return  <div
    className="ResultChild"
    style={{
        color: "white",
      border: "1px solid #ccc",
      padding: "10px",
      margin: "10px",
      cursor: "pointer",
      height: "200px",
    width: "200px",
    border: "2px solid red",
    margin: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.5rem",
    textAlign: "center",
    borderRadius: "10px",
    backgroundColor: "#F52F57",
      backgroundColor: props.isSelected ? "#F52F57" : "#F52F57"
    }}
    onClick={handleConfetti}>
    {props.isSelected && props.name}
    <audio ref={audioRef} src={pop} />

</div>
}

export default ResultChild