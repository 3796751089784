import React, { useRef, useState } from "react"
import "./result.css"
import ResultChild from "./ResultChild"


let Data = [
    { name: "DEVESH", state: 0, id: 1 },
    { name: "Anirudh Saroha", state: 0, id: 2 },
    { name: "BHAVAY SINGLA", state: 0, id: 3 },
    { name: "ABHINAV RAJPUT", state: 0, id: 4 },
    { name: "Pranjal Chaurasia", state: 0, id: 5 },
    { name: "Kanishk Jain", state: 0, id: 6 },
    { name: "Amlan Nandi", state: 0, id: 7 },
    { name: "Ankush Singh", state: 0, id: 8 },
    { name: "Abhishek", state: 0, id: 9 },
    { name: "Vivek Gupta", state: 0, id: 10 },
    { name: "MOHD HASSAN", state: 0, id: 11 },
    { name: "Sonal Verma", state: 0, id: 12 },
    { name: "Hiten Jangra", state: 0, id: 13 },
    { name: "Divyansh Dubey", state: 0, id: 14 },
    { name: "Bipul Kumar", state: 0, id: 15 },
    { name: "Arjit Saxena", state: 0, id: 16 },
    { name: "Yateen Kumar", state: 0, id: 17 },
    { name: "Pankaj Singh", state: 0, id: 18 },
    { name: "Nikhil Dixit", state: 0, id: 19 },
    { name: "Arihant Shrivastav", state: 0, id: 20 },
    { name: "Aditya Singh", state: 0, id: 21 },
    { name: "Mayank Taneja", state: 0, id: 22 },
    { name: "Aditya Kumar Jha", state: 0, id: 23 },
    { name: "Manika Jain", state: 0, id: 24 },
    { name: "Prashant Pal", state: 0, id: 25 },
    { name: "Virat Gupta", state: 0, id: 26 },
    { name: "Arpit Maurya", state: 0, id: 27 },
    { name: "Aditya Sharma", state: 0, id: 28 },
    { name: "AYUSHMAN SINGH", state: 0, id: 29 },
    { name: "SAGAR", state: 0, id: 30 },
    { name: "Pragya Choudhary", state: 0, id: 31 },
    { name: "Bhumika", state: 0, id: 32 },
    { name: "Nishtha Agrawal", state: 0, id: 33 },
    { name: "Aarzoo Kapoor", state: 0, id: 34 },
    {name: "Tamanna Kapoor", state: 0, id: 35},
    { name: "Lovish Manchanda", state: 0, id: 36 },
    {name: "Priyanshu Barik", state: 0, id: 37}
  ];
  


    
function Results() {
    const [selectedId, setSelectedId] = useState(null);
    const audioRef = useRef(null);


    const handleCardClick = (id) => {
        setSelectedId(id); 
        if (audioRef.current) {
            audioRef.current.play();
          }
      };

    const nameStateHandler = (id) => {
        console.log(id)
        let element = Data.filter((Element) => id==Element.id)
        console.log(element)
        element.state = 1
        console.log(element)
    }

    return (<>
        <div className="Title">GDSC: WEB-TEAM : Results : 2024-25</div>
        <div className="ResultContainer">
        {Data.map((Element) => {
            return <ResultChild audioref={audioRef} isSelected={selectedId === Element.id} onCardClick={handleCardClick} state={Element.state} nameStateHandler={nameStateHandler} name={Element.name} id={Element.id}/>
        })}
        </div></>
    )
}

export default Results;