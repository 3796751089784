import logo from './logo.svg';
import './App.css';
import visionImpairment from "./Assets/visionImpairment.svg"
import writings from "./Assets/Writings.svg"
import { useState } from 'react';
import { AnimatePresence, motion } from "framer-motion"
import ExpandableCard from './Components/ExpandableCards';
import SVGPathAnimation from './Components/SvgPathTracer';
import MobileWritingCards from './Components/MobileWritingCards';
import MobileWorksCards from './Components/MobileWorksCards';
import MobileJEECards from './Components/MobileJEECards';
import MobileDisabilityCards from './Components/MobileDisabilityCards';
import MobileFearCards from './Components/MobileFearCards';


function App() {

  const [writingsClicked, setWritingsClicked] = useState(false);
  const [rankClicked, setRankClicked] = useState(false);
  const [DisabilityClicked, setDisabilityClicked] = useState(false)
  const [WorksClicked, setWorksClicked] = useState(false)
  const [modalColor, setModalColor] = useState("rgba(255, 255, 255, 0.4)")
  const [modalState, setModalState] = useState(true)
  const [modalID, setModalID] = useState(-1)

  const writingsClickHandler = () => {
    setWritingsClicked(true)
  }

  const rankClickHandler = () => {
    setRankClicked(prevState => !prevState)
  }

  const disabilityClickedHandler = () => {
    setDisabilityClicked(true)
  }

  const worksClickedHandler = () => {
    setWorksClicked(true)
  }

  const modalhandler = (id) => {
    setModalID(id)
    switch (id) {
      case 1:
        setModalColor("rgba(245, 47, 87, 0.4)")
        break;
      case 2:
        setModalColor("rgba(169, 253, 172, 0.4)")
        break;
      case 3:
        setModalColor("rgba(252, 228, 216, 0.4)")
        break;
      case 4:
        setModalColor("rgba(188, 182, 255, 0.4)")
        break;
      case 5:
        setModalColor("rgba(252, 228, 216, 0.4)")
        break;
      default:
        setModalColor("#fff")
        break;
    }

    setModalState(1)
  }

  const modalCloseHandler = () => {
    setModalState(0)
  }

  return (
    <div className="App">
      <div className="Container">
        <div className="ContainerOne">
          <motion.div 
          
          whileHover={{borderRadius: "50px"}}
          
          onClick={writingsClickHandler} className="Writings">
             {!writingsClicked ? 
             <img className="writing_svg" src={writings}/> :
             <div className='ContentContainer'>
                <motion.h2 style={{color: "#fff"}} initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }}>Writings</motion.h2>
                <hr/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.02}}>
                <ExpandableCard 
                    Title={"lorem ipsum"}
                    Subtitle={"alksdf asdf adf asdf as fd asdf ad fasdf  dfs asf asdf ads fasdfa fa"}
                  />
                </motion.div>
                <hr/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.04}}>
                <h3>lorem ipsum</h3>
                  <p>alksdf asdf adf asdf as fd asd
                  f ad fasdf  dfs asf asdf ads fasdfa fa</p>
                </motion.div>
                <hr/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.06}}>
                  <ExpandableCard 
                    Title={"lorem ipsum"}
                    Subtitle={"alksdf asdf adf asdf as fd asdf ad fasdf  dfs asf asdf ads fasdfa fa"}
                  />
                </motion.div>
                <hr/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.08}}>
                <h3>lorem ipsum</h3>
                  <p>alksdf asdf adf asdf as fd asd
                  f ad fasdf  dfs asf asdf ads fasdfa fa</p>
                </motion.div>
                <hr/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.04}}>
                  <h3>lorem ipsum</h3>
                  <p>alksdf asdf adf asdf as fd asd
                  f ad fasdf  dfs asf asdf dfasdf
                  adsfasdf
                  adfasdfasd
                  fasdfasdfasdfasdfasdfa adsfasdf a ads fa ads fasdfa fa</p>
                </motion.div>
                <hr/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.04}}>
                  <ExpandableCard 
                    Title={"lorem ipsum"}
                    Subtitle={"alksdf asdf adf asdf as fd asdf ad fasdf  dfs asf asdf ads fasdfa fa"}
                  />
                </motion.div>
                <hr/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.04}}>
                  <h3>lorem ipsum</h3>
                  <p>alksdf asdf adf asdf as fd asd
                  f ad fasdf  dfs asf asdf ads fasdfa fa</p>
                </motion.div>
                <hr/>
             </div>}
          </motion.div>
          
          <div className="CenterContainer">
            <motion.div
            whileHover={{borderRadius: "30px"}}
             onClick={rankClickHandler} className="NameBox">
                <motion.div
                className='Rank'
                
                initial= {{x: 0, y: 0}}
                animate= {{x: rankClicked ? -45 : 0, y: rankClicked ? -12: 0}}
                exit={{x:0, y:0}}
                >12833{rankClicked && "/23237"}</motion.div>
               {rankClicked && <motion.div
                initial= {{x: 0, y: 0}}
                animate= {{x:10 ,y: -25}}
                exit={{x:0, y:0}}
                className='rankStory'>Chasing something like a horse with blinders on,<br/> upon reaching the finish line i got the trophy but lost the vision to see the shine.</motion.div>}
                {rankClicked && <motion.div
                style={{border: "2px solid black", padding: "10px"}}
                initial= {{x: 0, y: 0}}
                animate= {{x:-115 ,y: -10}}
                exit={{x:0, y:0}}
                whileHover={{borderRadius: "15px"}}
                >My JEE Journey</motion.div>}
            </motion.div>
            <motion.div 
              whileHover={{scale: 1.01, 
                      textShadow: "0px 0px 100px rgb(255,255,255)",
                      boxShadow: "0px 0px 8px rgb(0,0,0)"
                      }} 
             className="Name">
                Reyansh Sinha
            </motion.div>
          </div>
          <motion.div
            whileHover={{borderRadius: "50px"}}
           onClick={disabilityClickedHandler} className="Disability">
              {!DisabilityClicked ? 
                  <img className="Vision_svg" src={visionImpairment}/> :
                  <div className='DisabilityContainer'>
                    <motion.h2 style={{color: "#000"}} initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }}>My Experience with Diability</motion.h2>
                    <hr style={{borderBottom: "2px solid #000"}}/>
                    <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.04}}>
                  <ExpandableCard 
                    Title={"lorem ipsum"}
                    Subtitle={"alksdf asdf adf asdf as fd asdf ad fasdf  dfs asf asdf ads fasdfa fa"}
                  />
                </motion.div>
                <hr style={{borderBottom: "2px solid #000"}}/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.04}}>
                  <h3>lorem ipsum</h3>
                  <p>alksdf asdf adf asdf as fd asd
                  f ad fasdf  dfs asf asdf ads fasdfa fa</p>
                </motion.div>
                <hr style={{borderBottom: "2px solid #000"}}/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.04}}>
                  <ExpandableCard 
                    Title={"lorem ipsum"}
                    Subtitle={"alksdf asdf adf asdf as fd asdf ad fasdf  dfs asf asdf ads fasdfa fa"}
                  />
                </motion.div>
                <hr style={{borderBottom: "2px solid #000"}}/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.04}}>
                  <h3>lorem ipsum</h3>
                  <p>alksdf asdf adf asdf as fd asd
                  f ad fasdf  dfs asf asdf ads fasdfa fa</p>
                </motion.div>
                <hr style={{borderBottom: "2px solid #000"}}/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.04}}>
                  <ExpandableCard 
                    Title={"lorem ipsum"}
                    Subtitle={"alksdf asdf adf asdf as fd asdf ad fasdf  dfs asf asdf ads fasdfa fa"}
                  />

                </motion.div>
                <hr style={{borderBottom: "2px solid #000"}}/>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{delay: 0.04}}>
                  <h3>lorem ipsum</h3>
                  <p>alksdf asdf adf asdf as fd asd
                  f ad fasdf  dfs asf asdf ads fasdfa fa</p>
                </motion.div>
                <hr style={{borderBottom: "2px solid #000"}}/>
                  </div>
              }
          </motion.div>
        </div>
        <div className="ContainerTwo">
          <motion.div 
          whileHover={{borderRadius: "50px"}}
           onClick={worksClickedHandler} className="Work">
              <motion.div
                className='Works'
                initial={{x: 0, rotate: 0}}
                animate={{x: WorksClicked ? -270 : 0, rotate: WorksClicked ? -90 : 0}}
              >
                Works
              </motion.div>
             {WorksClicked &&<div style={{position: "absolute"}}> 
             <motion.div 
              initial={{opacity: 0}}
              animate={{x: -50, opacity: 1}}
              transition={{delay: 0.5}}
              style={{fontSize: "2rem"}}>
                Web Development
              </motion.div>
              <motion.div
                initial={{opacity: 0}}
                animate={{x: -50, opacity: 1}}
                transition={{delay: 0.9}}
               style={{fontSize: "2rem"}}>
                Project Management 
              </motion.div>
              <motion.div 
              initial={{opacity: 0}}
                animate={{x: -50, opacity: 1}}
              transition={{delay: 0.085}}
              style={{fontSize: "2rem"}}>
                Leadership
              </motion.div>
              <motion.div 
                initial={{opacity: 0}}
                animate={{x: -50, opacity: 1}}
                transition={{delay: 0.5}}
               style={{fontSize: "2rem"}}>
                Analytics
              </motion.div> </div>}
          </motion.div>
          <motion.div whileHover={{scale: 0.99, 
                      textShadow: "0px 0px 20px rgb(0,0,0)",
                      boxShadow: "0px 0px 8px rgb(0,0,0)",
                      borderRadius: "50px"
                      }} 
                      
                      className="FearAndFailures">
              Fear & Failures
          </motion.div>
          
        </div>
      </div>

      {/* mobile containeer */}
      <div className='mobileContainer'>
          <div className='mobileName'>Reyansh Sinha <br/> B.Tech, Mathematics & Computing<br/>Delhi Technological University</div>
          <div onClick={() => modalhandler(1)} className='mobileWritings'>Writings</div>
          <div onClick={() => modalhandler(2)} className='mobileWorks'>Works</div>
          <div onClick={() => modalhandler(3)} className='mobileJee'>12833</div>
          <div onClick={() => modalhandler(4)} className='mobileDisability'>Disability</div>
          <div onClick={() => modalhandler(5)} className='mobileFear'>Fear & Failures</div>
          <AnimatePresence>
          {modalState && <motion.div initial={{y: 400, scale: 0}} animate={{y: 0, scale: 1}} exit={{y: 800, scale: 0}} style={{backgroundColor: modalColor}} className='mobileModal'>
            <div onClick={modalCloseHandler} className='modalCloser'>x</div>
            <div className='modalCardsContainer'>
              {modalID == 1 && <MobileWritingCards/>}
              {modalID == 2 && <MobileWorksCards/>}
              {modalID == 3 && <MobileJEECards/>}
              {modalID == 4 && <MobileDisabilityCards/>}
              {modalID == 5 && <MobileFearCards/>}
            </div>
          </motion.div>}
          </AnimatePresence>
      </div>
    </div>
  );
}

export default App;
